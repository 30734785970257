b, strong {font-weight: bold !important;}
.no-outline, .btn, .btn:focus {outline: none !important;}
.loading-page {position: absolute;left: 0;width: calc(100% - 40px);height: calc(100% - 40px);background: #fff;margin: 20px;}
.loading-page img {position: absolute;top:0;bottom:0;margin:auto;left: 0;right: 0;}
.hidden, .hide {
    display: none !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0;
}
.modal-open .ReactModalPortal, .modal-open .ReactModalPortalv, .modal-open .ReactModalPortalr, .modal-open .ReactModalPortalc, .modal-open .ReactModalPortall, .modal-open .ReactModalPortalrd {position:fixed;top:0;right:0;bottom:0;left:0;z-index:1050;outline:0;overflow-x:hidden;overflow-y:auto;}

.ReactModalPortal .modal-dialog, .ReactModalPortalv .modal-dialog, .ReactModalPortalr .modal-dialog, .ReactModalPortalc .modal-dialog, .ReactModalPortall .modal-dialog, .ReactModalPortalrd .modal-dialog {-webkit-transition:-webkit-transform 0.3s ease-out;transition:-webkit-transform 0.3s ease-out;transition:transform 0.3s ease-out;transition:transform 0.3s ease-out, -webkit-transform 0.3s ease-out;-webkit-transform:translate(0, -25%);transform:translate(0, -25%)}

.ReactModalPortal .modal-dialog, .ReactModalPortalv .modal-dialog, .ReactModalPortalr .modal-dialog, .ReactModalPortalc .modal-dialog, .ReactModalPortall .modal-dialog, .ReactModalPortalrd .modal-dialog {-webkit-transform:translate(0, 0);transform:translate(0, 0)}

.ReactModal__Body--openv .ReactModalPortalv, .ReactModal__Body--openr .ReactModalPortalr, .ReactModal__Body--openc .ReactModalPortalc, .ReactModal__Body--openl .ReactModalPortall, .ReactModal__Body--openrd .ReactModalPortalrd {z-index: 2000 !important;}

.overlay-z {z-index: 9999 !important;}

.invoice-logo-w {width: 80px;}
.invoice-logo-w img {max-width: 100%;}
/* @media(min-width: 992px){ */
  .modal-lg {max-width: 1200px;}
  .modal-sm {max-width: 400px;}
  .modal-sm.sd {max-width: 340px;}
  .invoice-w {max-width: 1024px;}
/* } */
.span {display: inline-block; width: 184px;}
.user-title {
  font-size: 13px;
}
.table .row-actions a {margin-right: 0.42rem;color: #3b75e3; text-decoration: none;}
.table .row-actions a:last-child {margin-right: 0 !important;}
.cursor {cursor: pointer !important;}
.no-cursor {cursor: text !important;}
.el-tablo.bigger .value {font-size: 2rem;}
.el-tablo.bigger .label {font-size: 10px;}
.elabel {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}
.elabel-primary {background-color: #1890ff;}
.elabel-danger {background-color: #d9534f;}
.elabel-success {background-color: #5cb85c;}
.elabel-info {background-color: #383d41;}
.elabel-warning {background-color: #ffb443;}
.tr-warning {background-color: #ffb443 !important;}
.tr-danger {background-color: #d9534f !important; color: #fff !important;}
.scroll {overflow-x: scroll !important;}
.report {width: 100% !important;max-width: 100% !important;}
.scroll::-webkit-scrollbar {
    -webkit-appearance: none;
}

.scroll::-webkit-scrollbar:vertical {
    width: 11px;
}

.scroll::-webkit-scrollbar:horizontal {
    height: 11px;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}

.scroll::-webkit-scrollbar-track { 
    background-color: #fff; 
    border-radius: 8px; 
}

.input-group-preappend, .input-group-append {
	display: flex;
}
.input-group-append {
	margin-left: -2px;
}
.input-group>.input-group-append>.btn, .all-wrapper .input-group>.input-group-append>.fc-button, .input-group>.input-group-append>.input-group-text, .input-group>.input-group-prepend:not(:first-child)>.btn, .all-wrapper .input-group>.input-group-prepend:not(:first-child)>.fc-button, .input-group>.input-group-prepend:not(:first-child)>.input-group-text, .input-group>.input-group-prepend:first-child>.btn:not(:first-child), .all-wrapper .input-group>.input-group-prepend:first-child>.fc-button:not(:first-child), .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.input-group-text {
	display: flex;
	align-items: center;
	padding: .375rem .75rem;
	margin-bottom: 0;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	text-align: center;
	white-space: nowrap;
	border: 1px solid #cecece;
	border-radius: 4px;
	background: #e9ecef;
}

.react-datepicker-popper {z-index: 1000 !important;}
.white-bg { background: #fff !important; }
.top-menu-secondary li:focus {
	outline: none !important;
}
.link {color: #047bf8 !important; text-decoration: underline !important;}

.element-box .element-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 1rem 0;
    margin-top: 2rem;
    position: relative;
    z-index: 1
}

.element-box .element-header:after {
    content: "";
    background-color: #047bf8;
    width: 22px;
    height: 6px;
    border-radius: 2px;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0px
}
.top-settings {
  cursor: pointer;
}

.support-index .support-tickets-header .tickets-control .new-ticket {
  position: relative;
  margin-left: auto;
}
.ticket-header span {font-weight: 100;}
.btn {cursor: pointer;}

.modal-sm {max-width: 480px;}
.modal-md {max-width: 768px;}
.modal-ol {max-width: 1024px;}
.controls-below-table {
  margin: 1rem;
}
.disabled-link {pointer-events: none;color: #495057;}
.ticket-closed {
  font-style: italic;text-align: center;font-weight: 600;font-size: 0.85rem;border: 1px #f3f3f7 solid;color:#495057;
}
i.badge {font-style: normal;}
.messages-notifications .message-content, .messages-notifications .no-message-content {
  width: 240px;
}
.messages-notifications .no-message-content {
  color: #383d41;padding-left: 20px;padding-right: 20px;
}
.os-dropdown.message-list {
  height: 280px;
    overflow-y: scroll;
}
.pagination-center {
  position: relative;justify-content: flex-end;
}
.ant-pagination-total-text {
  position: absolute;left: 0;top: 0;
}
.aec-reply .btn-success {color: #fff !important;}
.aec-reply .mail-editor {
  min-height: 100px;
  max-height: 300px;
  border: 1px solid #F1F1F1;padding: 0 12px;
  padding: 12px;
}
.code-box-demo {
  padding: 42px 24px 50px;
  color: rgba(0,0,0,0.65);
}
.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0,0,0,0.25);
}
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
  margin-bottom: 8px;
}
.reply-header .os-icon-ui-15 {
  font-size: 0.86rem !important;
  position: relative !important;
  top: auto !important;
  left: auto !important;
}
.pointer {cursor: pointer !important;}
.public-DraftStyleDefault-block {
  margin: 0;
}
.block-ui-message-container {top: 25%;}
.el-tablo:not(.centered) {padding-right: 1.0rem;}
.el-tablo.narrow {padding: 1.5rem 1.2rem;}
.el-tablo .value {font-size: 1.04rem;}

.element-search:before {z-index: 100;}
.search-block {width: 400px;margin-bottom: 20px;}
.search-block .element-search input {background-color: #fff;}
.search-block .element-search .input-group-append {
  background-color: #fff;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  cursor: pointer;
}
.search-block .element-search .input-group-text {
  background-color: transparent;
  border: 0 none;
}
.flex {display: flex !important;}
.react-autosuggest__container {margin: 0 0 4px !important;}
.aec-alert {padding: 4px 15px !important;}
.row-expand-icon {
	color: #1890ff;
	text-decoration: none;
	cursor: pointer;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
	display: inline-block;
	width: 17px;
	height: 17px;
	color: inherit;
	line-height: 13px;
	text-align: center;
	background: #FFF;
	border: 1px solid #e8e8e8;
	border-radius: 2px;
	outline: none;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.row-collapsed::after {
	content: '+';
}
.row-expanded::after {
	content: '-';
}
.expanded-row {
	background: #fbfbfb !important;
}
.expanded-row .table {
  background: #fbfbfb;
}
.expanded-row .table-striped tr {
  background: #fbfbfb !important;
}
.btn-export {
  position: absolute;
  right: 15px;
  top: 1rem;
  margin-top: 2rem;
  z-index: 4000;
}
.btn-export:focus, .btn-export:active {
  outline: 0 none !important;
  color: #fff !important;
}
.btn-export-sm {
  position: absolute;
  right: 15px;
  top: 0;
  margin-top: 0;
  z-index: 1000;
}
.btn-export-sm:focus, .btn-export-sm:active {
  outline: 0 none !important;
  color: #fff !important;
}
.os-progress-bar.info .bar-level-1 {
  background-color: #F2F2F2;
}
.ant-popover-inner-content {
  padding: 0;
}
.ant-popover {
  z-index: 2030 !important;
}
.fs-name.nowrap {
  font-size: 0.7rem !important;
}
.lender-switch .os-dropdown.message-list {
  height: auto !important;
}
.bold {font-weight: bold !important;}
.approve .dropzone {
  min-height: 0;
}
.approve .dropzone .dz-message {
  margin: 0;
}
.is-responsive {
  overflow-x: scroll;
}
.nav-tabs .nav-item.active .nav-link {
  color: #464a4c;
  background-color: transparent;
  border-color: #ddd #ddd transparent;
  position: relative;
}
.is-responsive::-webkit-scrollbar {
  height: 0.85em;
}
.is-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.is-responsive::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.col_label {
  width: 90px;
  flex: unset;
}
.date_label {
  width: 45px;
  flex: unset;
  padding: 0;
}
.date_input {
  flex: unset;
  width: 160px;
  padding: 0;
}
.date_input .form-control {
  width: 160px;
}
.gen_input {
  flex: unset;
  width: 220px;
  padding: 0;
}
.gen_input .form-control {
  width: 220px;
}
.modal-allow {
  overflow-x: visible !important;
  overflow-y: visible !important;
}
.modal-extra-lg { max-width: 1300px; }
.ucase {
  text-transform: uppercase;
}
.block-ui-container {
  z-index: 9999;
}
.loan-detail .col-sm-4 {
  padding-right: 10px;padding-left: 10px;
}
.loan-detail .el-tablo {
  padding: 1.1rem 1.2rem !important;
}
.text-md {
    font-size: 1.42rem;
    line-height: 36px;
    margin-left: 30px;
}

.audit-block {
  display: flex;
}
.audit-block .blocks {
  padding: 8px;
}
.audit-block .blocks h4 {
  text-align: center;
}
.btn-block {
  padding: 20px;
  color:#fff;
  display: inline-block;
}
.inline-block {display: inline-block !important;}
.dp-slim {
  padding: 2px 20px;min-height: 0;
}
.dp-slim .dz-message {
  margin: 0.2em 0;
}
.dp-padd {
  padding: 0.8rem 1.2rem;
}
.ant-tooltip {
  z-index: 9999;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  display: block !important;
}
.width-100 {
  width: 100%;
}

.dropdown__menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: .9rem;
  color: #3E4B5B;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem;
}

.hide__menu{
  display: none;
}

.extra-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}


.ReactModal__Overlay {
  overflow: visible !important;
}

.merchant-value {
  position: absolute;
  top: 0px;
  right: 10px;
  border-radius: 50%;
  background: #31c37f;
  font-size: 0.7rem;
  color: rgb(255, 255, 255);
  height: 26px;
  width: 26px;
}

.btn-edit {
  position: absolute;
  right: 15px;
  top: 1rem;
  margin-top: 0;
  z-index: 4000;
}
